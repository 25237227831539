<template>
  <div class=" py-5">
    <div class="container">
      <transition name="vertical">
      <div class="containerInput" >
        <InputDados background="#175d93"  />
      </div>
      </transition>
    </div>
  </div>
</template>

<script>
import InputDados from "@/components/dataTeste/dados_input";
export default {
  components: { InputDados },
  methods: {}
};
</script>

<style scoped>
h1 {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
}

h3 {
  color: #fff;
  font-size: 15px;
}

.flexnomes{
    display: flex;
    justify-content: space-around;
    width: 70%;
    align-items: center;
    position: relative;
}

.containerInput {
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  border-color: #357eb8;
  background-color: #357eb8;
  border-style: solid;
  border-width: 0px 3px 0px 3px;
  max-height: 60vh;
}

.base {
  position: absolute;
  left: 0px;
}

.data {
  position: relative;
  right: -130px;
}

.unidade {
  position: relative;
  right: -105px;
}
</style>
